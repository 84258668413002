import qs from 'qs';

import { userStore } from '@/store/user/user.store.ts';

import { config } from '../config.ts';
import { HTTPMethod } from './http.enum.ts';

type Options = {
  method: HTTPMethod;
  body?: string | FormData;
  headers?: Record<string, string>;
  credentials?: 'include';
};

export class HTTPRequest {
  body: object | FormData | null = null;
  query: object | null = null;
  path: string | null = null;
  token: string | null = null;
  contentType: string | null = 'application/json';
  method: HTTPMethod = HTTPMethod.GET;

  public getUrl(): string {
    if (!this.path) {
      throw new Error('Path is null');
    }

    const url = new URL(this.path, config.URL_API);
    if (this.query) {
      url.search = qs.stringify(this.query);
    }

    return url.href;
  }

  private isChildericApiUrl(): boolean {
    return this.getUrl().includes(config.URL_API);
  }

  getOptions(): Options {
    const options: Options = {
      method: this.method,
    };

    if (this.isChildericApiUrl()) {
      options.credentials = 'include';
    }

    const headers = this.getHeaders();
    if (headers) {
      options['headers'] = headers;
    }

    const body = this.getBody();
    if (body) {
      options.body = body;
    }

    return options;
  }

  getBody(): string | FormData | undefined {
    if (!this.body) return undefined;
    if (this.body instanceof FormData) return this.body;
    return JSON.stringify(this.body);
  }

  getHeaders(): Record<string, string> {
    const xsrfToken = userStore.getState().token;
    const hasXsrfToken = typeof xsrfToken === 'string';

    const headers: Record<string, string> = {};

    const body = this.getBody();
    if (this.contentType !== null && body && !(body instanceof FormData)) {
      headers['Content-Type'] = this.contentType;
    }

    if (this.isChildericApiUrl() && hasXsrfToken) {
      headers['x-xsrf-token'] = xsrfToken;
    }

    return headers;
  }
}
