import PersonIcon from '@mui/icons-material/Person';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import upperFirst from 'lodash/upperFirst';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ListHistoriesOutput } from '@/api/history/list-histories/list-histories.output.ts';
import { I18n } from '@/enum/common/i18n.enum.ts';
import { useLang } from '@/hook/useLang.ts';
import { getDateTimeString } from '@/util/date.ts';
import { I18nNs } from '@/util/i18n.ts';

import { HistoryRelation } from './HistoryRelation.tsx';

type Props = {
  history: ListHistoriesOutput['docs'][0];
};

export function HistoryListItem({ history }: Props) {
  const { t } = useTranslation([I18nNs.History, I18nNs.Enum]);
  const lang = useLang();

  // Fix new names for order line
  const data = useMemo(() => {
    const d = history.data;
    if (!d) return history.data;

    if (d.orderProductNameFr && !d.orderLineNameFr) {
      d.orderLineNameFr = d.orderProductNameFr;
    }

    if (d.orderProductNameEn && !d.orderLineNameEn) {
      d.orderLineNameEn = d.orderProductNameEn;
    }

    if (d.orderLineNameFr && !d.orderProductNameFr) {
      d.orderProductNameFr = d.orderLineNameFr;
    }

    if (d.orderLineNameEn && !d.orderProductNameEn) {
      d.orderProductNameEn = d.orderLineNameEn;
    }

    if (!d.orderLineName) {
      d.orderProductName = lang === I18n.FR ? d.orderLineNameFr : d.orderLineNameEn;
    }
    return d;
  }, [history?.data, lang]);

  return (
    <TimelineItem key={history.id}>
      <TimelineSeparator>
        <TimelineConnector />
        <TimelineDot>
          {history.avatar ? (
            <Avatar sx={{ width: 40, height: 40 }} src={history.avatar} />
          ) : (
            <PersonIcon sx={{ width: 40, height: 40 }} />
          )}
        </TimelineDot>
        <TimelineConnector />
      </TimelineSeparator>
      <TimelineContent sx={{ py: '12px', px: 2 }}>
        <Typography variant="h6" component="span">
          {t(`enum.historyEntity.${history.entity}`)} - {t(`enum.historyAction.${history.action}`)}
        </Typography>

        <Typography sx={{ m: 'auto 0' }} align="left" variant="body2" color="text.secondary">
          {upperFirst(getDateTimeString(history.createdAt, lang))}
        </Typography>

        <Typography>
          {t(`message.${history.entity}.${history.action}` as string, data as any).toString()}
        </Typography>

        <Box sx={{ ml: -1 }}>
          <HistoryRelation history={history} />
        </Box>
        {/* <code>
          message.{history.entity}.{history.action}
        </code> */}
      </TimelineContent>
    </TimelineItem>
  );
}
